/* =============== Sass- variables ============ */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* $theme-colors: (
    'primary':#FFF8E7,
);

$bg-color: #FFF8E7;
$primary: #FCB400;
$secondary: #FE8F00;
$gray: #797979;
$btn-color: linear-gradient(90deg, #FCB400 0.05%, #FF6F00 99.95%);
$secondary-btn: #FFE5C3; */

img {
  width: 100%;
  height: 100%;
}


/* h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px !important
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.gradient-primary {
  background-image: linear-gradient(90deg, #FCB400 0.05%, #FF6F00 99.95%);
}

.form-input button {
  height: 50px;
  min-height: auto;
}


.otp-field {
  text-align: center !important;
  flex-grow: 1;
  border: none;
  margin-bottom: 10px;
  color: #3e3e3e;
  padding: 4px 0 5px;
  border-bottom: 2px solid #767676;
  margin: 0px 10px;

}

.otp-field:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid #d4582a;
}

.otp-container {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-container>div {
  width: 100%;
}

.input-field-upload {
  text-align: center;
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  padding: 20px 0px;
  overflow: hidden;
}

.input-field-upload:hover {
  border-color: #d4582a;
}

.upload-lable {
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.file-upload-icon {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #a3a3a3;
}

.file-upload-icon svg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  color: #d4582a;
}

.form-upload-img {
  width: 150px;
  height: 150px;
  overflow: hidden !important;
  margin : 0 auto;
}

.form-upload-pdf {
  width: 250px;
  height: 300px;
  overflow: hidden !important;
}



.react-pdf__Page {
  height: 400px !important;
}

.react-pdf__Page__canvas {
  width: 250px !important;
  height: 300px !important;
}

.form-upload-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-upload-img iframe {
  pointer-events: none;
}

.form-upload-img iframe ::-webkit-scrollbar {
  width: 0px !important;
}


.error-red-border {
  border-color: #d32f2f !important;
}

.sidebar-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.sidebar-icon svg {
  object-fit: contain;
}

.sidebar-icon svg:hover {
  cursor: pointer;
}

.chip span {
  font-size: 14px !important;
}


/* input[type='date']:in-range::-webkit-datetime-edit-year-field,
input[type='date']:in-range::-webkit-datetime-edit-month-field,
input[type='date']:in-range::-webkit-datetime-edit-day-field,
input[type='date']:in-range::-webkit-datetime-edit-text {
    display: none;
} */

.date-range-btn {
  background-color: #F4F4F5;
  padding: 10px;
  padding-top: 24px;
  height: 50px;
  border-radius: 10px;
  font-size: 13px;
  color: black;
  position: relative;
}

.date-range-btn span {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #5c5c5c;
  transition: all 0.3s ease-in-out;
}

.date-range-btn svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 18px;
}

.dated-range-btn span {
  top: 27%;
}

.date-menu {
  position: absolute;
  width: 50%;
  top: 70px;
  right: 1%;
  z-index: 999999;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.date-menu .usermenu {
  width: 100%;
}

.hide-date-menu {
  display: none;
}

.date-menu .usermenu-button {
  width: 96%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 20px 0;
}

.date-menu .usermenu-button button {
  background-color: #FCB400;
  width: 100px;
}

.rdrDateRangePickerWrapper {
  display: block !important;
}

.rdrDateRangePickerWrapper,
.rdrDateRangePickerWrapper {
  width: 100% !important;
}

.rdrDefinedRangesWrapper {
  padding: 20px 20px 0px 20px !important;
}

.rdrDefinedRangesWrapper {
  width: 100% !important;
}

.rdrCalendarWrapper {
  width: 100%;
}

.rdrStaticRanges {
  flex-direction: row !important;
  justify-content: space-around;
  gap: 15px;
}

.rdrInputRanges {
  display: flex;
  justify-content: center;
}

.rdrMonth {
  width: 50% !important;
}

.rdrStaticRangeLabel {
  padding: 0px !important;
}

.rdrDayNumber span {
  color: black !important;
}

.rdrDayNumber span::after {
  background: rgba(46, 46, 46, 0.877) !important
}


@media only screen and (max-width:1320px) {

  .date-menu {
    width: 65%;
  }
}

@media only screen and (max-width:1024px) {

  .date-menu {
    width: 75%;
  }
}

@media only screen and (max-width:900px) {

  .date-menu {
    width: 100%;
  }
}

@media only screen and (max-width:730px) {

  .date-menu {
    width: 110%;
    right: -5%;
  }
}